import {
	CLEAR_BOOKING,
	UPDATE_BOOKING,
	UPDATE_BOOKING_ACTIVITY,
	UPDATE_BOOKING_ACTIVITY_DATE,
	UPDATE_BOOKING_CHILDREN_BIRTHDATES,
	UPDATE_BOOKING_INFANTS_BIRTHDATES,
	UPDATE_BOOKING_PASSENGER,
	UPDATE_SDP_FORM_DESTINATION,
} from "app/actionTypes";

import { SAVE_QUOTATION_PAYLOAD } from "app/pages/Booking/bookingActionTypes";

export const saveQuotationPayload = (
	quotationpayload,
	productUri,
	isChildrenBirthdateRequired,
	shop
) => {
	return {
		type: SAVE_QUOTATION_PAYLOAD,
		quotationpayload,
		product: { uri: productUri, isChildrenBirthdateRequired },
		shop,
	};
};

export const updateBooking = booking => {
	return {
		type: UPDATE_BOOKING,
		booking,
	};
};

export const updateBookingActivity = activity => {
	return {
		type: UPDATE_BOOKING_ACTIVITY,
		activity,
	};
};

export const updateBookingActivityDate = (code, date) => {
	return {
		type: UPDATE_BOOKING_ACTIVITY_DATE,
		payload: {
			code,
			date,
		},
	};
};

export const updateBookingPassager = (type, count) => {
	return {
		type: UPDATE_BOOKING_PASSENGER,
		passengerType: type,
		count,
	};
};

export const updateBookingChildrenBirthdate = (birthdate, index) => {
	return {
		type: UPDATE_BOOKING_CHILDREN_BIRTHDATES,
		birthdate: birthdate,
		index,
	};
};

export const updateBookingInfantsBirthdate = (birthdate, index) => {
	return {
		type: UPDATE_BOOKING_INFANTS_BIRTHDATES,
		birthdate: birthdate,
		index,
	};
};

export const clearBooking = () => {
	return {
		type: CLEAR_BOOKING,
	};
};

export const updateSDPFormDestination = destinationResort => {
	return {
		type: UPDATE_SDP_FORM_DESTINATION,
		destinationResort,
	};
};
