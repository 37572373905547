/* eslint-disable array-callback-return */
import { useEffect } from "react";

const isClassNamesValid = classNames =>
	(Array.isArray(classNames) || typeof classNames === "string") && classNames.length > 0;

const useOverlay = (componentRef, isOpen, classNames) => {
	useEffect(() => {
		if (componentRef && componentRef.current && isClassNamesValid(classNames)) {
			if (isOpen) {
				[classNames].flatMap(className => {
					componentRef.current.classList.add(className);
				});
			} else {
				[classNames].flatMap(className => {
					componentRef.current.classList.remove(className);
				});
			}
		}
	}, [componentRef, isOpen]);
};

export default useOverlay;
