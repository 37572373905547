import { memo, useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import Drawer from "../../../.shared/Drawer/Drawer";
import useVisualViewport from "app/utils/hooks/useVisualViewport";
import "./HomeSmartDPSearchInput.scss";
import SvgIconMagnifyingGlassMedium from "app/pages/.shared/IconMagnifyingGlassMedium";
import { AsyncSmartDPSearchFormContainer } from "app/pages/SmartDP/SmartDPSearchSummary/SmartDPSearchSummary";
import IconRightSmall from "app/pages/.shared/static/icons/IconRightSmall";
import PropTypes from "prop-types";
import { EventCategory, HitType, useFlagship } from "@flagship.io/react-sdk";
import { FS_SDP_FORM_SEARCH_EVENT_NAME } from "app/constants";

const HomeSmartDPSearchInput = props => {
	const { smartDPRef } = props;
	const [isOpen, setOpen] = useState(false);
	const [viewports] = useVisualViewport();
	const handleClose = useCallback(() => {
		setOpen(false);
	}, []);
	const { hit: fsHit } = useFlagship();

	const handleOpen = useCallback(() => {
		fsHit.send({
			type: HitType.EVENT,
			category: EventCategory.ACTION_TRACKING,
			action: FS_SDP_FORM_SEARCH_EVENT_NAME.CLICK_ON_MINI_SEARCH,
		});
		setOpen(true);
	}, []);

	return (
		<div className="home-smartDP-search-input">
			<div className="home-smartDP-search-input__content" onClick={handleOpen}>
				<div className="home-smartDP-search-input__icon-left">
					<SvgIconMagnifyingGlassMedium />
				</div>

				<label htmlFor="homeSearchInput" className="home-smartDP-search-input__label">
					{<FormattedMessage id="sdp.search.home.input.label" />}
				</label>
				<div className="home-smartDP-search-input__icon-right">
					<IconRightSmall width={9} height={14} />
				</div>
			</div>
			<Drawer
				isOpen={isOpen}
				direction={"rtl"}
				sidePanelClassName="home-smartDP-search-input-side-panel"
				drawerClassName="home-smartDP-search-input-side-panel__content"
				style={{ height: viewports?.visualViewport?.height }}
				parentRef={smartDPRef}
			>
				<AsyncSmartDPSearchFormContainer {...props} hideSidePanel={handleClose} />
			</Drawer>
		</div>
	);
};

HomeSmartDPSearchInput.propTypes = {
	smartDPRef: PropTypes.object,
};

export default memo(HomeSmartDPSearchInput);
