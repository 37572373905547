import { useEffect } from "react";

const useDrawerOverlay = (ref, isOpen) => {
	/**
	 * Gère l'effet d'opacité de la div référencée lorsque le drawer est ouvert ou fermé.
	 * Cela permet de corriger un bug qui laisse apparaître les elements de la div referencé
	 * lorsque la barre de navigation iOS disparaît au moment du scrolling vers le bas.
	 */
	useEffect(() => {
		if (ref && ref.current) {
			if (isOpen) {
				ref.current.classList.add("drawer__overlay");
			} else {
				ref.current.classList.remove("drawer__overlay");
			}
		}
	}, [ref, isOpen]);
};

export default useDrawerOverlay;
