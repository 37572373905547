import { memo, useCallback, useContext, useEffect, useLayoutEffect, useState } from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import { FormattedDate, FormattedMessage } from "react-intl";
import { messagePropType } from "app/utils/propTypes";
import "./DateCalendarInput.scss";
import classNames from "classnames";
import AppGlobalsContext from "app/AppGlobalsContext";
import IconArrowRightCircle from "app/pages/.shared/static/icons/IconArrowRightCircle";
import IconArrowLeftCircle from "app/pages/.shared/static/icons/IconArrowLeftCircle";
import { sendTagOnPriceCalendarDateClicked } from "app/utils/analytics";
import Calendar from "react-calendar";
import {
	autoUpdate,
	FloatingPortal,
	offset,
	shift,
	useClick,
	useDismiss,
	useFloating,
	useFocus,
	useInteractions,
} from "@floating-ui/react";
import IconCloseFull from "app/pages/.shared/IconCloseFull";
import useOverlay from "app/utils/hooks/useOverlay";
import LoaderBar from "app/pages/.shared/LoaderBar/LoaderBar";
import Typography, { TYPOGRAPHY_VARIANTS } from "app/pages/.shared/Typography/Typography";

// pour les tests e2e
const getTileClassname = ({ date }) => {
	return `date-calendar-input-tile__tile date-calendar-input-tile__tile--${date.getMonth() +
		1}-${date.getDate()}`;
};

const DateCalendarInput = props => {
	const [field, meta, helpers] = useField(props);
	const { shop } = useContext(AppGlobalsContext);
	const {
		id,
		label,
		className,
		departureDateMin,
		departureDateMax,
		checkDateAvailability = () => {},
		isCalendarDisabled,
		popperOffset = [],
		calendarDisabledView,
		updateFloatingPositionReference = {},
		componentToOverlayRef = {},
		loading,
		overlayClassNames,
	} = props;

	const [startDate, setStartDate] = useState(new Date());

	const [open, setOpen] = useState(false);

	useOverlay(componentToOverlayRef, open, overlayClassNames);

	useEffect(() => {
		if (departureDateMin) {
			setStartDate(new Date(departureDateMin));
		}
	}, [departureDateMin]);

	const handleDateSelect = useCallback(date => {
		helpers.setValue({
			departureDate: date[0],
			endDate: date[1],
		});

		// close only if departureDate and endDate to selected
		if (date[0] && date[1]) {
			helpers.setTouched(true);
			helpers.setError();
			setOpen(false);
		}
	}, []);

	const isTouched = (meta.touched && !meta.error) || open || field.value?.departureDate;

	const inputClassName = classNames("date-calendar-input", className, {
		"date-calendar-input--opened": open,
		"date-calendar-input--touched": isTouched,
		"date-calendar-input--error": meta.touched && meta.error,
	});

	const minDate = departureDateMin && new Date(departureDateMin);
	const maxDate = departureDateMax && new Date(departureDateMax);

	const handleCleanDate = useCallback(() => {
		helpers.setValue({});
		helpers.setTouched(false);
	}, []);

	const handleActiveStartDateChange = useCallback(({ activeStartDate, action }) => {
		if (action === "next" || action === "prev") {
			setStartDate(new Date(activeStartDate));
		}
	}, []);

	const { context, x, y, strategy, refs } = useFloating({
		placement: "bottom",
		whileElementsMounted: autoUpdate,
		open,
		onOpenChange: open => {
			// clear date if only departure date is selected when closing calendar
			if (field.value?.departureDate && !field.value?.endDate) {
				handleCleanDate();
			}
			setOpen(open);
		},
		middleware: [offset(), shift()],
	});

	const dismiss = useDismiss(context);
	const click = useClick(context);
	// gere le click et le focus par clavier
	const focus = useFocus(context, {
		keyboardOnly: false,
	});

	const { getReferenceProps, getFloatingProps } = useInteractions([dismiss, click, focus]);

	// const handleScroll = useCallback(() => {
	// 	refs.reference.current.blur();
	// }, [refs.reference.current]);
	//
	// useEffect(() => {
	// 	// fermeture du calendrier au scroll
	// 	// Ne pas supprimer cette condition "Cypress === "undefined"", sinon les tests e2e peuvent échouer
	// 	if (typeof Cypress === "undefined") {
	// 		window.addEventListener("scroll", handleScroll);
	// 	}
	//
	// 	return () => {
	// 		if (typeof Cypress === "undefined") {
	// 			window.removeEventListener("scroll", handleScroll);
	// 		}
	// 	};
	// }, [handleScroll]);

	useLayoutEffect(() => {
		updateFloatingPositionReference(refs);
	}, [refs]);

	return (
		<>
			<div
				ref={refs.setReference}
				className={inputClassName}
				data-testid={props["data-testid"]}
				tabIndex="0"
				{...getReferenceProps()}
			>
				{!loading ? (
					<>
						<label htmlFor={id} className="date-calendar-input__label">
							{isTouched ? (
								<Typography variant={TYPOGRAPHY_VARIANTS.XSMALL} isBold>
									{label}
								</Typography>
							) : (
								<Typography variant={TYPOGRAPHY_VARIANTS.REGULAR}>
									{label}
								</Typography>
							)}
						</label>
						<div className="date-calendar-input__input">
							{field.value?.departureDate ? (
								<FormattedDate
									value={field.value?.departureDate}
									day="2-digit"
									month="2-digit"
									year={"2-digit"}
								/>
							) : (
								<span className="date-calendar-input__placeholder">
									{open && (
										<FormattedMessage id="sdp.search.departure.date.side.panel.title" />
									)}
								</span>
							)}
							{field.value?.endDate ? (
								<FormattedDate
									value={field.value?.endDate}
									day="2-digit"
									month="2-digit"
									year={"2-digit"}
								>
									{dateToDisplay => ` - ${dateToDisplay}`}
								</FormattedDate>
							) : (
								<span className="date-calendar-input__placeholder">
									{open && (
										<>
											<span className="date-calendar-input__dash">-</span>
											<FormattedMessage id="sdp.search.destination.date.side.panel.title" />
										</>
									)}
								</span>
							)}

							{open && field.value?.departureDate && field.value?.endDate && (
								<div
									className="date-calendar-input__close_icon"
									onClick={handleCleanDate}
								>
									<IconCloseFull width={17} height={17} />
								</div>
							)}
						</div>
					</>
				) : (
					<div className="date-calendar-input__loader">
						<LoaderBar height={10} width={"80%"} />
						<LoaderBar height={10} />
					</div>
				)}
			</div>
			{!loading && (
				<FloatingPortal preserveTabOrder={false}>
					{open && (
						<div
							ref={refs.setFloating}
							tabIndex={-1}
							className="date-calendar-input__popover"
							{...getFloatingProps({
								style: {
									position: strategy,
									left: x + popperOffset[0] ?? "",
									top: y + popperOffset[1] ?? "",
								},
							})}
						>
							<>
								<Calendar
									activeStartDate={startDate}
									onActiveStartDateChange={handleActiveStartDateChange}
									prevLabel={<IconArrowLeftCircle />}
									nextLabel={<IconArrowRightCircle />}
									className="date-calendar-input__calendar"
									locale={shop}
									onChange={handleDateSelect}
									maxDate={maxDate}
									minDate={minDate}
									value={[field.value?.departureDate, field.value?.endDate]}
									showDoubleView
									selectRange
									allowPartialRange
									defaultActiveStartDate={field.value?.departureDate || minDate}
									onClickDay={sendTagOnPriceCalendarDateClicked}
									tileClassName={getTileClassname}
									tileDisabled={({ date }) => checkDateAvailability(date)}
								/>
								{open && isCalendarDisabled && calendarDisabledView}
							</>
						</div>
					)}
				</FloatingPortal>
			)}
		</>
	);
};

DateCalendarInput.propTypes = {
	id: PropTypes.string,
	label: messagePropType,
	className: PropTypes.string,
	departureDateMin: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
	departureDateMax: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
	icon: PropTypes.element,
	checkDateAvailability: PropTypes.func,
	popoverWidth: PropTypes.number,
	["data-testid"]: PropTypes.string,
	isCalendarDisabled: PropTypes.bool,
	updateFloatingPositionReference: PropTypes.func,
	calendarDisabledView: PropTypes.element,
	loading: PropTypes.bool,
	popperOffset: PropTypes.array,
	componentToOverlayRef: PropTypes.object,
	overlayClassNames: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default memo(DateCalendarInput);
